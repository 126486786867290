<template>
  <div>
    <component
      :is="wrapComponent"
      :to="memberRoute"
      target="_blank"
    >
      <div>{{row.name}}</div>
      <div>{{row.email}}</div>
    </component>
  </div>
</template>

<script lang="babel" type="text/babel">
import listDataItem from '@/components/list/mixins/listDataItem'
export default {
  mixins: [listDataItem],
  data: () => ({
    
  }),
  computed: {
    wrapComponent() {
      if(!this.memberRoute) return 'div'
      return 'router-link'
    },
    memberRoute() {
      if(!this.member) return null
      return {
        name: 'member-update',
        params: {
          target: this.row.member.id
        }
      }
    },
    member() {
      return this.row.member
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>